@import '../src/app/modules/dashboard/src/styles';

@import 'codemirror/lib/codemirror.css';

sl-card::part(base) {
    box-shadow: 0 5px 16px 3px #e5e6eb;
    border-radius: 10px;
}

core-header {
    padding: 14px 0 !important;
    margin: 0;
}

sl-tooltip {
    &::part(base) {
        word-break: break-word;
    }

    &::part(body) {
        padding: 8px 16px;
    }
}

sl-input {
    &::part(form-control-label) {
        max-height: 16px;
    }
}

.CodeMirror {
    color: var(--sl-color-secondary-900);
    height: 100%;
}

.CodeMirror-gutters {
    background: transparent;
    border: none;
}

.CodeMirror-linenumber {
    padding-left: 0;
    padding-right: var(--sl-spacing-2x-small);
    color: var(--sl-color-secondary-300);
}

.cdk-overlay-dark-backdrop {
    background-color: var(--sl-overlay-background-color);
}

.cdk-overlay-container {
    z-index: 999;
}
