.plmt-typography(@font-size, @font-weight, @line-height) {
    font-size: @font-size;
    font-weight: @font-weight;
    line-height: @line-height;
}

/* Заголовки основного экрана. Не должны использоваться на экране более одного раза. */

.plmt-display-1-600 {
    .plmt-typography(48px, 600, 52px);
}

.plmt-display-2-400 {
    .plmt-typography(40px, 400, 48px);
}

.plmt-display-3-500 {
    .plmt-typography(32px, 500, 40px);
}

.plmt-display-4-400 {
    .plmt-typography(28px, 400, 36px);
}

/* Заголовки и подзаголовки. Могут использоваться несколько раз на одной странице,
но только один раз внутри раздела. */

.plmt-headline-1-600 {
    .plmt-typography(24px, 600, 28px);
}

.plmt-headline-2-600 {
    .plmt-typography(20px, 600, 24px);
}

.plmt-headline-2-500 {
    .plmt-typography(20px, 500, 24px);
}

.plmt-subheader-1-500 {
    .plmt-typography(17px, 500, 24px);
}

.plmt-subheader-2-500 {
    .plmt-typography(15px, 500, 20px);
}

.plmt-subheader-3-500 {
    .plmt-typography(13px, 500, 18px);
}

/* Для абзацев, описаний и других блоков текста, составляющих основную часть
пользовательского интерфейса или области контента. */

.plmt-body-1-600 {
    .plmt-typography(18px, 600, 24px);
}

.plmt-body-1-500 {
    .plmt-typography(18px, 500, 24px);
}

.plmt-body-1-400 {
    .plmt-typography(18px, 400, 24px);
}

.plmt-body-2-700 {
    .plmt-typography(15px, 700, 20px);
}

.plmt-body-2-500 {
    .plmt-typography(15px, 500, 20px);
}

.plmt-body-2-400 {
    .plmt-typography(15px, 400, 20px);
}

.plmt-body-3-700 {
    .plmt-typography(13px, 700, 16px);
}

.plmt-body-3-500 {
    .plmt-typography(13px, 500, 16px);
}

.plmt-body-3-400 {
    .plmt-typography(13px, 400, 16px);
}

.plmt-body-4-700 {
    .plmt-typography(12px, 700, 16px);
}

.plmt-body-4-500 {
    .plmt-typography(12px, 500, 16px);
}

.plmt-body-4-400 {
    .plmt-typography(12px, 400, 16px);
}

/* Подписи. Используются для хлебных крошек, текстовых подсказок и других дополняющих
элементов интерфейса. */

.plmt-caption-1-600 {
    .plmt-typography(11px, 600, 16px);
}

.plmt-caption-1-400 {
    .plmt-typography(11px, 400, 16px);
}

.plmt-caption-2-500 {
    .plmt-typography(9px, 500, 12px);
}

.plmt-caption-2-400 {
    .plmt-typography(9px, 400, 12px);
}
