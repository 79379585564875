@import '../../../../../node_modules/codemirror/theme/night.css';
@import '../../../../../node_modules/devextreme/dist/css/dx.light.compact.css';

@import (reference) 'core-library.less';

@defaultTheme: #00afd7, #10069f, #1857f0, #c724b1, #fedb00, #ff9900, #78d64b,
    #0c83e4, #8a1f7a, #e45d2b, #f5c7d1, #0e0f7d, #70b5ec, #d76bc8, #6f6cc3;

@themes: {
    polymatica: @defaultTheme;

    c1: #6e5c97, #1a2244, #192f5e, #275793, #4c82b4, #77b2c5, #f2e3ea, #eacad5,
        #708098, #9ab4d4, #b9cde3, #dce3ef;

    c2: #508164, #44564a, #8e6d6e, #e5c7b7, #ebe2de, #f7f3f3, #d8e7fa, #7c9080,
        #776e81, #a091b7, #cec3df, #ded9e3;

    grey: #b3b3b3, #919191, #cccaca, #323232, #e1e1e1, #7d7d7d, #b9b9b9, #555555,
        #696969, #cccaca, #555555, #7d7d7d, #282727, #696969, #393939, #919191;

    green: #dc413e, #803a48, #af717b, #ce9998, #c7bfbc, #d2d2da, #d45837,
        #723424, #46555c, #81aab3, #b4ccd3, #d3e1e6;

    pink: #ff0097, #285aff, #e5004d, #4f32ff, #f20202, #9e00ff, #ff015c, #7701ee,
        #d6036c, #6903cf, #9d024f, #5208b0, #6a0437, #4e0399, #360920, #320460;

    blue: #286b7b, #214753, #548ba1, #96c4d8, #cbe3ee, #e0eef7, #919ca4, #303232,
        #325051, #9ab7b8, #d9e4e7, #ebebf1;

    yellow: #ffba08, #faa307, #f48c06, #e85d04, #dc2f02, #d00000, #9d0208,
        #6a040f, #270936, #410460, #670399, #7d03ba, #8b03cf, #aa00ff, #be3ffd,
        #d175ff;

    red: #d4e0e8, #92b7c7, #415e51, #88a176, #aabd93, #d2d6b1, #45a4af, #376d72,
        #3a454e, #6e6e6b, #d8cebb, #f0e9e8;

    mixed: #47c2ff, #ff877c, #00aaff, #ff8075, #0396e0, #ff5b4d, #0185c8,
        #cf4c40, #0073ad, #ba3529, #006598, #992c22, #005682, #601d17, #06173a,
        #ac0e00;

    circus: #00afd7, #0063d7, #8122cb, #cb22af, #cb2240, #ff1102, #ff702e,
        #ffda02, #ed4e0a, #9c0761, #58047f, #0c047f, #074965, #045a7f, #52a8a3,
        #63cfa7;

    custom1: #ffaa01, #9c9c9c, #addf26, #814fb0, #1c8fbe, #de3e7e, #00b058,
        #ff3131, #ffe131, #3d6048, #eba6ff, #00ffc5, #86bdf6, #006a91, #89766f,
        #f67a7a;
};

:root {
    each(@defaultTheme, {
        @i: @index - 1;

        --theme-color-@{i}: @value;
    });

    --sl-z-index-toast: 1001;
}

each(@themes, {
    .theme-@{key} {
        each(@value, {
            @i: @index - 1;

            --theme-color-@{i}: @value;
        })
    }
});

.editor {
    .ck-content {
        height: 90vh;
    }

    .ck.ck-toolbar {
        background: none;
        border: none;
    }
}

.small-editor {
    .ck-editor__editable {
        height: 200px;
    }
}

app-widget-setup plmt-data-option-setup .dataset {
    max-height: 78vh !important;
}

lib-block-filters {
    margin-bottom: var(--sl-spacing-medium);
    display: block;
}

dx-tree-list.checkbox-list {
    .dx-treelist-container {
        background: transparent;
    }

    .dx-treelist-empty-space {
        width: 0;
    }
}

.dx-treelist-rowsview .dx-column-lines.dx-row:not(.dx-row-focused):hover > td {
    background-color: var(--plmt-neutral-100);
    color: #333;
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td {
    color: var(--plmt-primary-500);
    background-color: var(--plmt-primary-100);
}

dx-tree-view.single .dx-treeview-node {
    padding-left: 0;
}

core-header {
    margin-bottom: var(--sl-spacing-large);
}

core-header,
core-content {
    padding: 0 !important;
}

.svg-map svg path:hover {
    filter: brightness(0.9);
    transition: fill 0.2s ease-in-out;
}

.dx-datagrid {
    .dx-row > td {
        padding: 0 !important;
        height: 1px;
    }

    .dx-datagrid-headers .dx-row > td {
        padding: 5px !important;
    }
}

:not(:root):fullscreen::backdrop {
    background: transparent !important;
}

.NgxEditor {
    color: var(--sl-input-color) !important;
    border-radius: var(--sl-input-border-radius-small) !important;
    border: 1px solid var(--sl-input-border-color) !important;

    .plmt-body-2-400;

    &:hover {
        border-color: var(--sl-input-border-color-hover) !important;
    }
}

.NgxEditor__Placeholder:before {
    color: var(--sl-input-placeholder-color) !important;

    .plmt-body-2-400;
}
